const name = "WALLET";

export const FETCH_WALLET_ETHEREUM_BALANCES = `${name}/FETCH_WALLET_ETHEREUM_BALANCES`;
export const FETCH_WALLET_ETHEREUM_BALANCES_START = `${FETCH_WALLET_ETHEREUM_BALANCES}/START`;
export const FETCH_WALLET_ETHEREUM_BALANCES_END = `${FETCH_WALLET_ETHEREUM_BALANCES}/END`;
export const FETCH_WALLET_ETHEREUM_BALANCES_FAILURE = `${FETCH_WALLET_ETHEREUM_BALANCES}/FAILURE`;
export const FETCH_WALLET_ETHEREUM_BALANCES_SUCCESS = `${FETCH_WALLET_ETHEREUM_BALANCES}/SUCCESS`;

export const FETCH_WALLET_BALANCES = `${name}/FETCH_WALLET_BALANCES`;
export const FETCH_WALLET_BALANCES_START = `${FETCH_WALLET_BALANCES}/START`;
export const FETCH_WALLET_BALANCES_END = `${FETCH_WALLET_BALANCES}/END`;
export const FETCH_WALLET_BALANCES_FAILURE = `${FETCH_WALLET_BALANCES}/FAILURE`;
export const FETCH_WALLET_BALANCES_SUCCESS = `${FETCH_WALLET_BALANCES}/SUCCESS`;

export const SET_WALLET_ADDRESS = `${name}/SET_WALLET_ADDRESS`;

export const UNLOCK = `${name}/UNLOCK`;

export const RESET = `${name}/RESET`;

export const SET_NETWORK = `${name}/SET_NETWORK`;
