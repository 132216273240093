
export const lang = [
    'English',
    'Melayu',
    'Indonesia',
    'Thai',
    'Korean',
    'Japanese',
    'Hindi',
    'Nepalese',
    'Portuguese',
    'Mandarin',
    'Vietnamese'
]

export const Languages = {
    English: 'English',
    Melayu: 'Melayu',
    Indonesia: 'Indonesia',
    Thai: 'Thai',
    Korean: 'Korean',
    Japanese: 'Japanese',
    Hindi: 'Hindi',
    Portuguese: 'Portuguese',
    Mandarin: 'Mandarin',
    Vietnamese: 'Vietnamese',
    Nepalese: 'Nepalese'





}