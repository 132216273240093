import React from "react";
import "./HomePage.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GradientButton from "../components/buttons/GradientButton";
import HardwareOptionButton from "../components/buttons/HardwareOptionButton";
import Wallet, { EthereumOtherDerivationPath } from "../services/Wallet";
import {
  WalletUnlockStrategy,
  EthereumDerivationPath,
  EthereumLedgerLiveDerivationPath,
} from "../services/Wallet";
import { unlockWallet } from "../state/actions/Wallet";
import { getHardwareWalletAddresses } from "../state/actions/Wallet";
import { NavLink } from "react-router-dom";
import DropZone from "../components/DropZone";
import { SET_WALLET_ADDRESS } from "../state/types/Wallet";
import { withTranslation } from "react-i18next";
import TemporaryState from "../services/TemporaryState";
import Chart from "chart.js";
import ChartComponent from "./ChartComponent";

const classNames = require("classnames");

class Homepage extends React.Component {
  constructor() {
    super();
    this.state ={
      latestPrice : ""
    }
  }
  componentDidMount() {

    fetch("https://prodexplorer.pandoproject.org/api/lbank/price").then(
      (resp) => {
        resp.json().then((res) => {
          let resData = res.lBankPrice[0].latestPrice;
          this.setState({ latestPrice: resData });
          // console.log(this.state.latestPrice)
        });
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <section id="home-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-des">
                  <h2>
                    Your passport to a digital <br></br> financial revolution
                  </h2>
                  <p>
                    Welcome to Pando Wallet, your trusted platform for seamless
                    storage, transactions, and growth of your valuable Pando
                    Tokens
                  </p>
                  <div className="btn-des">
                    <Link to="/create" className="btn1">
                      Create Wallet
                    </Link>

                   
                    <a
                      href="https://pandoproject.org/mainnet-3-0/"
                      className="btn2"
                      target="_blank">
                      Mainnet 3.0
                    </a>

                  
                  </div>
                  <Link to="/unlock/keystore-file" className="btn124">
                      Unlock Wallet
                    </Link>
                  <div className="banner-img">
                    <img src="./img/home/banner.svg"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="head">
                  <h2>Advantages at a Glance</h2>
                  <p>Before you choose us, discover some of our benefits</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="h-100">
                  <div className="box f1">
                    <img src="./img/home/f1.png"></img>
                    <h5>Quick Access</h5>
                    <p>
                      Seamless storage and quick access to your Pando Tokens
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="h-100">
                  <div className="box f2">
                    <img src="./img/home/f2.png"></img>
                    <h5>Secure</h5>
                    <p>
                      Robust security measures to protect your digital assets
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="h-100">
                  <div className="box f3">
                    <img src="./img/home/f3.png"></img>
                    <h5>Tracking</h5>
                    <p>
                      Real-time updates and notifications for instant token
                      value tracking
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="statics">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="box-img">
                  <div className="box-flex">
                    <div className="d-flex align-items-center">
                      <img src="/img/home/token.png"></img>
                      <div className="">
                        <h6>PTX Price</h6>
                      </div>
                    </div>
                    <p>$ {this.state.latestPrice}</p>
                  </div>
                  <div className="lbank-img">
                    <a href="https://www.lbank.com/trade/ptx_usdt/" target="_blank">
                      <img src="/img/home/card.png"></img>
                    </a>
                  </div>
                  {/* <div className="img"> */}
                    {/* <img src="./img/home/finan.png"></img> */}
                    <ChartComponent/>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="des">
                  <h2>
                    Embrace the Pando revolution: <br></br>Safeguard, Transact,
                    and Prosper
                  </h2>
                  <p>
                    Unveiling the Crucial Elements that Shape Content Importance
                    for Your Success:
                  </p>
                  <ul>
                    <li>
                      Easy-to-use wallet interface for effortless Pando Token
                      management
                    </li>
                    <li>
                      Seamlessly connect with various platforms for smooth
                      transactions and exchanges
                    </li>
                    <li>
                      Unlock opportunities to stake, earn, and expand your Pando
                      Token holdings
                    </li>
                  </ul>
                  <Link to="/create" class="btn1">
                    Create Wallet
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer id="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="logo-txt">
                  <img src="/img/logo/pando_wallet_logo@2x.svg"></img>
                  <p>
                    Pando Wallet is a digital service to make your transactions
                    easy and quick.
                  </p>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/pandoprojectorg" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/pandoprojectorg" target="_blank">
                        <img src="/img/home/twtter.svg"></img>
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.com/invite/7uUFpYe7MD" target="_blank">
                        <i class="fab fa-discord"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/pandoprojectorg/?hl=en" target="_blank">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="links">
                      <h5>Browse</h5>
                      <ul>
                        <li>
                          <Link to="/create">Create Wallet</Link>
                        </li>
                        <li>
                          <Link to="/unlock/keystore-file">Login Wallet</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="links">
                      <h5>Quick links</h5>
                      <ul>
                        <li>
                          <a href="https://pandoproject.org/rametron/" target="_blank">Rametron</a>
                        </li>
                        <li>
                          <a href="https://pandoproject.org/mainnet-3-0/">Mainnet 3.0</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="links">
                      <h5>Company</h5>
                      <ul>
                        <li>
                          <a href="https://pandoproject.org/terms-of-service/">Terms of Services</a>
                        </li>
                        <li>
                          <a href="https://pandoproject.org/privacy-policy/">Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="links">
                      <h5>Contact us</h5>
                      <p>Contact us via email for assistance.</p>
                      <form>
                        <div className="input-field">
                          <a href="mailto:support@pandolab.io">support@pandolab.io</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright">
                  <p>
                    © {new Date().getFullYear()} <a href="https://pandoproject.org/" target="_blank">PandoProject</a> | Powered by
                   <a href="https://pandolab.io/"> Pandolab</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Homepage;
