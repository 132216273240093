import React, { useState, useEffect } from "react";
import "./DashboardPage.css";
import Chart from "chart.js";
import ChartComponent from "./ChartComponent";

function DashboardPage() {
  
  return (
    <div>
      <section id="tokens-page" className="dashb">
        <div className="container-fluid">
          {/* <div className="row">
                        <div className="col-lg-12">
                             <div className="tok-flex">
                                <h6>Tokens</h6>
                                <img data-bs-toggle="modal" data-bs-target="#exampleModal" src="/img/icons/add.png"></img>
                             </div>
                            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-title">
                                            <img src="/img/icons/token.png"></img>
                                            <h5>Add Token</h5>
                                        </div>
                                        <i className="fas fa-times close-btn" data-bs-dismiss="modal" aria-label="Close"></i>
                                        <div className="modal-body">
                                            <div className="form-box">
                                                <div class="row custom-top">
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Contract Address</span>
                                                            <input  type="text" name="text-397" placeholder="Enter contract address"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Token Name</span>
                                                            <input  placeholder="Enter Token Name" value="" type="text" name="text-398"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Symbol</span>
                                                            <input  placeholder="Enter Symbol" value="" type="email" name="email-348"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="input-field">
                                                            <span>Decimal</span>
                                                            <input  placeholder="Enter token decimal “7”, “9”" value="" type="tel" name="tel-95"></input>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <button>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="token-box-flex">
                            <div className="token-box">
                                <div className="lefty-flex">
                                    <img src="/img/icons/coin.png"></img>
                                    <div className="des">
                                        <h6>PTX <span>Pando Token</span></h6>
                                        <p>$4.23 <b>+2.99%</b></p>
                                    </div>
                                </div>
                                <div className="righty">
                                    <h5>200 PTX</h5>
                                    <h4>$ 100.005</h4>
                                </div>
                            </div>
                            <div className="token-box">
                                <div className="lefty-flex">
                                    <img src="/img/icons/coin.png"></img>
                                    <div className="des">
                                        <h6>PTX <span>Pando Token</span></h6>
                                        <p>$4.23 <b>+2.99%</b></p>
                                    </div>
                                </div>
                                <div className="righty">
                                    <h5>200 PTX</h5>
                                    <h4>$ 100.005</h4>
                                </div>
                            </div>
                            
                          </div>
                        </div>
                    </div> */}
          <div className="row">
            <div className="col-lg-12">
              <h4>Price Chart</h4>
              {/* <div className="chart-btn">
                <button
                  className={activeClass}
                  onClick={() => setFormat("MMM D")}>
                  D
                </button>
                <button className={activeClass} onClick={() => setFormat("ll")}>
                  W
                </button>
                <button
                  className={activeClass}
                  onClick={() => setFormat("MMM YYYY")}>
                  M
                </button>
              </div> */}
              <ChartComponent/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardPage;
