
import _ from "lodash";
import config from "../Config";

const Networks = {
    __deprecated__ETHEREUM: 'ethereum',


};


export function isPandoNetwork(network) {
    return (network !== Networks.__deprecated__ETHEREUM);
}

export function canGuardianNodeStake(network) {
    return true;
}

export function canViewSmartContracts(network) {
    return (network === config.chainId);
}

export default Networks;
