import React from "react";
import "./SettingsPage.css";
import apiService from "../services/Api";
import Wallet from "../services/Wallet";
import { withTranslation } from "react-i18next";
import Web3 from 'web3';
import pnc20 from "../pnc20";
import config from "../Config";
import get from "lodash/get";
import BigNumber from 'bignumber.js';


const rpcURL = config.rpcUrl
const web3 = new Web3(rpcURL);

class TransactionHistory extends React.Component {
  constructor(props) {
    super();
    this.state = { transactionData: [], transactionLoading: false, transactionDataMeta: [], ttype: 'ptx', tokenmap: {},transactionDataPnc721:[],transactionDataPnc20:[] };
  }
  calculagteTime = (time) => {
    let fulldate = new Date(Number(time) * 1000);
    return `${fulldate.getDate()}/${fulldate.getMonth() + 1
      }/${fulldate.getFullYear()} `;
  };

  selectType(type) {
  
    if (type == 'ptx') {
      this.setState({ ttype: 'ptx' })
    }
    else if (type == 'pnc20') {
      this.setState({ ttype: 'pnc20' })
      this.getPnc20txs()
    }
    else if (type == 'pnc721') {
      this.setState({ ttype: 'pnc721' })
      this.getPnc721txs()
    }
    else{
      this.setState({ ttype: 'ptx' })
      this.getTranscton()
    }
  }


  calcualteAmount = (amount) => {
    if (isNaN(amount)) {
      return '';
    }
    return amount.toFixed(2);
  };
  calcualteSmallAmount = (amount) => {
    if (isNaN(amount)) {
      return '';
    }
    return amount.toFixed(2);
  };
  render() {
    const { t } = this.props;
    return (
      <div className=" ">
        <div className="row">
          <div className="col-lg-12 mt-3">
            {this.state.transactionLoading ? (
              <p className="text-center mt-5">
                {t(`PLEASE_WAIT`)}.....{" "}
                <i
                  class="fa fa-spinner fa-spin fa-1x ml-2"
                  aria-hidden="true"
                ></i>
              </p>
            ) : (
              <>
                <div className="trans-his">
                  <div className="bg-mine wrapper-transacton p-3 text-center ">
                    <h6 style={{ color: "white" }}>

                      {t(`LATEST_TRANSACTIONS`)}{" "}
                    </h6>
                    <span
                      title="Refresh"
                      className=""
                      onClick={() => this.selectType()} id="Refresh">
                      <img
                        height="30"
                        src="/img/Path 716.svg"
                        className="mr-rht"
                      ></img>
                    </span>


                  </div>

                  <div className="tranasctiontype">
                    <span className={this.state.ttype === 'ptx' ? 'active' : ''} onClick={() => this.selectType('ptx')}>PTX</span>
                    <span className={this.state.ttype === 'pnc20' ? 'active' : ''} onClick={() => this.selectType('pnc20')}>PNC-20</span>
                    <span className={this.state.ttype === 'pnc721' ? 'active' : ''} onClick={() => this.selectType('pnc721')}>PNC-721</span>

                  </div>


                  {
                    this.state.ttype == 'ptx' ?

                      <div className="transaction-table table-responsive">
                        <table className="StakesTable ">
                          <thead>
                            <tr>
                              <th>{t(`TXN_HASH`)}</th>
                              <th>{t(`BLOCK`)}</th>
                              <th className="remove-space">{t(`TX_TYPE`)}</th>
                              <th>{t(`FROM`)}</th>
                              <th>{t(`TO`)}</th>
                              <th>{t(`DATE`)}</th>
                              <th>{t(`STATUS`)}</th>
                              <th>{t(`AMOUNT`)}</th>
                              <th>{t(`FEE`)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.transactionData.length ? (
                              this.state.transactionData.map((val, index) => {


                                if (val.type != 7 && index < 50) {

                                  return (
                                    <tr key={index}>
                                      <td>

                                        <a
                                          target="_blank"
                                          href={
                                            config.explorerFrontendURL + "/txs/" + val.hash
                                          }
                                        >
                                          {val.hash.substring(0, 10)}....
                                        </a>
                                      </td>
                                      <td>{val.block_height}</td>
                                      <td className="remove-space">
                                        {val?.data?.outputs[0]?.address.toUpperCase() !=
                                          Number(Wallet.getWalletAddress())
                                          ? `${t("SEND")}`
                                          : `${t(`RECEIVED`)}`}
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={
                                            config.explorerFrontendURL +
                                            `/account/` +
                                            val?.data?.inputs[0]?.address + '?tx=transfer'
                                          }
                                        >
                                          {val?.data?.inputs[0]?.address.substring(
                                            0,
                                            10
                                          )}
                                          ...
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={
                                            config.explorerFrontendURL +
                                            `/account/` +
                                            val?.data?.outputs[0]?.address + '?tx=transfer'
                                          }
                                        >
                                          {val?.data?.outputs[0]?.address.substring(
                                            0,
                                            10
                                          )}
                                          ...{" "}
                                        </a>
                                      </td>
                                      <td>{this.calculagteTime(val.timestamp)}</td>

                                      <td>
                                        {" "}
                                        <img
                                          src="/img/Path 476.svg"
                                          className={val?.status + ' fin23'}
                                        ></img>{" "}
                                        {val?.status}
                                      </td>
                                      <td>
                                        {this.calcualteAmount(
                                          (val?.data?.outputs[0]?.coins?.ptxwei /
                                            1000000000000000000)
                                        )}PTX
                                      </td>
                                      <td>
                                        {this.calcualteSmallAmount(
                                          val?.data?.fee?.ptxwei / 1000000000000000000
                                        )}
                                      </td>
                                    </tr>

                                  );
                                }
                              })
                            ) : (
                              <tr className="text-center">
                                <td colSpan="9" className="text-center">
                                  {t(`NO_TRANSACTION_HISTORY_FOUND`)}
                                </td>
                              </tr>
                            )}


                          </tbody>
                        </table>
                      </div>

                      : ''}


                  {
                    this.state.ttype == 'pnc20' ?
                      <div className="transaction-table table-responsive">
                        <table className="StakesTable ">
                          <thead>
                            <tr>

                              <th>{t(`TXN_HASH`)}</th>
                              <th>{t(`Token`)}</th>

                              <th className="remove-space">{t(`TX_TYPE`)}</th>
                              <th>{t(`FROM`)}</th>
                              <th>{t(`TO`)}</th>
                              <th>{t(`DATE`)}</th>
                              {/* <th>{t(`STATUS`)}</th> */}
                              <th>{t(`AMOUNT`)}</th>

                            </tr>
                          </thead>


                          <tbody>
                            {this.state.transactionDataPnc20.length ? (
                              this.state.transactionDataPnc20.map((val, index) => {
                                const name = get(this.state.tokenmap, `${val.contract_address}.name`) || val.name || "";
                                const decimals = get(this.state.tokenmap, `${val.contract_address}.decimals`);

                                const quantity = decimals ? this.formatQuantity(val.value, decimals) : val.value;
                                // console.log(this.state.tokenmap,quantity)
                                return (
                                  <tr key={index}>
                                    <td>

                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL + "/txs/" + val.hash
                                        }
                                      >
                                        {val.hash.substring(0, 10)}....
                                      </a>

                                    </td>
                                    <td className="conteonspa">
                                      <div className="tokness  ds34d">
                                        {/* <span>PNC-20</span> */}
                                        <a
                                          target="_blank"
                                          href={
                                            config.explorerFrontendURL +
                                            `/account/` +
                                            val?.contract_address + '?tx=contract'
                                          }
                                        >
                                          {name}

                                        </a>
                                      </div>


                                    </td>

                                    <td className="remove-space">
                                      {val.from.toUpperCase() !=
                                        Number(Wallet.getWalletAddress())
                                        ? `${t("SEND")}`
                                        : `${t(`RECEIVED`)}`}
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL +
                                          `/account/` +
                                          val?.from + '?tx=transfer'
                                        }
                                      >
                                        {val?.from.substring(
                                          0,
                                          10
                                        )}
                                        ...
                                      </a>
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL +
                                          `/account/` +
                                          val?.to + '?tx=transfer'
                                        }
                                      >
                                        {val?.to.substring(
                                          0,
                                          10
                                        )}
                                        ...{" "}
                                      </a>
                                    </td>
                                    <td>{this.calculagteTime(val?.timestamp)}</td>
                                    <td>
                                      {quantity}
                                    </td>

                                  </tr>

                                );

                              })
                            ) : (
                              <tr className="text-center">
                                <td colSpan="9" className="text-center">
                                  {t(`NO_TRANSACTION_HISTORY_FOUND`)}
                                </td>
                              </tr>
                            )}


                          </tbody>
                        </table>
                      </div>
                      : ''}




                  {
                    this.state.ttype == 'pnc721' ?
                      <div className="transaction-table table-responsive">
                        <table className="StakesTable ">
                          <thead>
                            <tr>

                              <th>{t(`TXN_HASH`)}</th>
                              <th>{t(`Token`)}</th>

                              <th className="remove-space">{t(`TX_TYPE`)}</th>
                              <th>{t(`FROM`)}</th>
                              <th>{t(`TO`)}</th>
                              <th>{t(`DATE`)}</th>
                              {/* <th>{t(`STATUS`)}</th> */}
                              <th>{t(`AMOUNT`)}</th>

                            </tr>
                          </thead>


                          <tbody>
                            {this.state.transactionDataPnc721.length ? (
                              this.state.transactionDataPnc721.map((val, index) => {

                                return (
                                  <tr key={index}>
                                    <td>

                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL + "/txs/" + val.hash
                                        }
                                      >
                                        {val.hash.substring(0, 10)}....
                                      </a>

                                    </td>
                                    <td className="conteonspa">
                                      <div className="tokness  ds34d">  {val?.name} <span>PNC-721</span>
                                        <a
                                          target="_blank"
                                          href={
                                            config.explorerFrontendURL +
                                            `/account/` +
                                            val?.contract_address + '?tx=contract'
                                          }
                                        >
                                          {val?.contract_address.substring(0, 10)
                                          }
                                          ...
                                        </a>
                                      </div>


                                    </td>

                                    <td className="remove-space">
                                      {val.from.toUpperCase() !=
                                        Number(Wallet.getWalletAddress())
                                        ? `${t("SEND")}`
                                        : `${t(`RECEIVED`)}`}
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL +
                                          `/account/` +
                                          val?.from + '?tx=transfer'
                                        }
                                      >
                                        {val?.from.substring(
                                          0,
                                          10
                                        )}
                                        ...
                                      </a>
                                    </td>
                                    <td>
                                      <a
                                        target="_blank"
                                        href={
                                          config.explorerFrontendURL +
                                          `/account/` +
                                          val?.to + '?tx=transfer'
                                        }
                                      >
                                        {val?.to.substring(
                                          0,
                                          10
                                        )}
                                        ...{" "}
                                      </a>
                                    </td>
                                    <td>{this.calculagteTime(val?.timestamp)}</td>

                                    {/* <td>
                                        {" "}
                                        <img
                                          src="/img/Path 476.svg"
                                          className={val.status + ' fin23'}
                                        ></img>{" "}
                                        {val.status}
                                      </td> */}
                                    <td>
                                      {this.calcualteAmount(
                                        (val?.value /
                                          1000000000000000000)
                                      )}

                                      {/* {val.type == 7 ?
                                          <b>
                                            {val.symbol}
                                          </b>
                                          :
                                          <b>
                                            PTX
                                          </b>
                                        } */}
                                    </td>

                                  </tr>

                                );

                              })
                            ) : (
                              <tr className="text-center">
                                <td colSpan="9" className="text-center">
                                  {t(`NO_TRANSACTION_HISTORY_FOUND`)}
                                </td>
                              </tr>
                            )}


                          </tbody>
                        </table>
                      </div>
                      : ''}
                </div>





                {this.state.transactionData.length > 19 &&  this.state.ttype == 'ptx' ? (
                  <a
                    href={
                      config.explorerFrontendURL +
                      `/account/` +
                      Wallet.getWalletAddress()
                    }
                    className="sd"
                    target="_blank"
                  >
                    {t(`VIEW_ALL`)}
                  </a>
                ) : null}

                {this.state.transactionDataPnc20.length > 19 &&  this.state.ttype == 'pnc20' ? (
                  <a
                    href={
                      config.explorerFrontendURL +
                      `/account/` +
                      Wallet.getWalletAddress()
                    }
                    className="sd"
                    target="_blank"
                  >
                    {t(`VIEW_ALL`)}
                  </a>
                ) : null}


                {this.state.transactionDataPnc721.length  > 19 &&  this.state.ttype == 'pnc721' ? (
                  <a
                    href={
                      config.explorerFrontendURL +
                      `/account/` +
                      Wallet.getWalletAddress()
                    }
                    className="sd"
                    target="_blank"
                  >
                    {t(`VIEW_ALL`)}
                  </a>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.scrollTo(0, document.body.scrollHeight);
    this.getTranscton();
  }
  componentWillUnmount() {
    clearInterval(this.stime);
  }

  getTranscton() {
    this.setState({ transactionLoading: true });
    let address = Wallet.getWalletAddress();
    apiService
      .getTransactionHistory(address)
      .then((data) => {

        if (data.body && data.body.length > 0) {
          let txbody = []
          for (let i of data.body) {

            if (i.type == 2) {
              txbody.push(i)
            }

          }

          let uniqueSet = new Set(txbody.map(JSON.stringify));
          let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

          for (let i of uniqueArray) {

            i.data.fee = Object.fromEntries(
              Object.entries(i.data.fee).map(([k, v]) => [k.toLowerCase(), v])
            );

            i.data.inputs[0].coins = Object.fromEntries(
              Object.entries(i.data.inputs[0].coins).map(([k, v]) => [k.toLowerCase(), v])
            );

            i.data.outputs[0].coins = Object.fromEntries(
              Object.entries(i.data.outputs[0].coins).map(([k, v]) => [k.toLowerCase(), v])
            );

            this.setState({ transactionData: uniqueArray });

          }
          let pendingTX = []
          if (sessionStorage.getItem('pendingTX')) {
            pendingTX = JSON.parse(sessionStorage.getItem('pendingTX'))
          }
          for (let i of pendingTX) {
            i.timestamp = Math.floor(Date.now() / 1000)
            this.state.transactionData.unshift(i)
          }
          let asd = setInterval(() => {
            for (let i of pendingTX) {
              apiService.getHashDetails(i.hash).then((data) => {
                if (data.body.status == 'finalized') {
                  this.state.transactionData.shift();
                  pendingTX.shift();
                  sessionStorage.setItem('pendingTX', JSON.stringify(pendingTX))
                  this.getTranscton();
                  document.getElementById("Refresh1").click();
                  document.getElementById("Refresh").click();
                  clearInterval(asd)
                }
              })
            }
          }, 5000)
        }

        else {
        }
        this.setState({ transactionLoading: false });
      })
      .catch(() => {
        this.setState({ transactionLoading: false });
      });


  }


  getPnc20txs() {
    this.setState({ transactionLoading: true });
    let address = Wallet.getWalletAddress();
    apiService.pnc2tokentx(address.toLowerCase()).then((res) => {
      let txs = res.body;
      txs = txs.sort((a, b) => b.timestamp - a.timestamp);
      let addressSet = new Set();
      txs.forEach(tx => {
        if (tx.contract_address) {
          addressSet.add(tx.contract_address);
        }
      })
      if (addressSet.size === 0) {
        return;
      }
      apiService.getTokenSummary([...addressSet])
        .then(res => {
          console.log(res)
          let infoList = get(res, 'body') || [];
          let map = {};

          infoList.forEach(info => {
            map[info.contract_address] = {
              name: info.name,
              decimals: info.decimals
            }
          })
          this.setState({ tokenmap: map });
        })

      this.setState({ transactionDataPnc20: res.body })
      this.setState({ transactionLoading: false });
    })
    .catch(() => {
      this.setState({ transactionLoading: false });
    });
  }



  getPnc721txs() {
    this.setState({ transactionLoading: true });
    let address = Wallet.getWalletAddress();
    apiService.pnc721tokentx(address.toLowerCase()).then((res) => {
      this.setState({ transactionDataPnc721: res.body })
      this.setState({ transactionLoading: false });
    }) .catch(() => {
      this.setState({ transactionLoading: false });
    });
  }

  formatQuantity(amount, decimals, length = 4) {
    decimals = decimals || 0;
    let wei = new BigNumber(10).exponentiatedBy(decimals);
    return new BigNumber(amount).dividedBy(wei).decimalPlaces(length).toFormat({
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    })
  }

}

export default withTranslation()(TransactionHistory);
