const config = {
    isPandoNetworkLive: true,
    defaultPandoChainID:'pandonet',
    BASE_URL: "https://mainnetapi.pandoproject.org/apis",
    explorerApiUri: 'https://prodexplorer.pandoproject.org/api',
    explorerFrontendURL: 'https://explorer.pandoproject.org',
    rpcUrl:'https://eth-rpc-api.pandoproject.org/rpc',
    name:'Mainnet',
    version:'3.0',
    githubURL: 'https://github.com/pandoprojects/pando-network-protocol/releases/tag/2.0.0',
    pubaddr: ["0xdf1f3d3ee9430db3a44ae6b80eb3e23352bb785e" ,"0xfC4Ce3e7821452F231Bf9808ccB772DcD3A39466" ,"0x40072Ff87F6dA7863ff817CcF7dAc8AE91aaD235","0xaa17D140dcA1211596Ef736607D6B438fCF83b4F","0x72084182C2e437fEBfCfA3302E97a9cDD29473F2","0x5F9dF17Fa2062B645edC617C74fB4C10BEa28dC9","0xc6AcB7A045DE93Be54cDa3d46681B1dA67b01b8a" ,"0x99eac60C09E1443C147eD3BeA20C11643F257A2C","0x5c4de059c0Be5c06D6a4f7358E6351023459c358","0x8D2Ab25274bAE1ead9B4f78987b0912a2a6e4DA9" ,"0xdDF1FCCBc38ac9c9A1fB47506EB18643dadA5f03" ,"0xB0053320331A776d7De096bB24f62E446eB0846d","0xdf7E136daf354495ddb9CaBd56708a1180E72310","0xf199084e21B9088AE506Fd2b2ddcDDd727Fa7d4D","0x8dF141e211592de07e59666C3037992519f07427","0x1eF0A39A2bb051767Bde61555AF14909060F0714","0x3BEd00955e2b5F65e4053018748984252D5a468a","0x7efE54C774b6097023752d8F7AD5701C0d51e6aa","0x9B8385aAAdd304721a0E4bC18D8a9e7C0236B90A","0x87b88DD0b9aa61329A0c02C52D1C9a95668b5de0","0x2820D434bC99bb7e35eD2c65A8306E585D188B98","0xCca8E9123F43e0D1c5b3c929a9AFD1FE3D6A3781","0xD893ba4631f6c2Ab037360439A7F50615041d015","0x9C265bD9d15c1EAeA3708b39b3108728B57Db04D","0xFaE153084Fc78A045b8352922BA56DB41B171a25","0xf50FDdEA47c81764f897fB33ea38c501dF391E8f","0xcb4f90af1cccc8e5ad7a8282573a21713767f213","0x867bde62063a5bc6ae7054ebea71f05d3d84c53a"],
    currentFee: 0.1,
    stakeTransactionFee: 100000000000000000,
    gasLimit: 1000000,
    chainId: 'Mainnet',
    faucetUrl: 'https://forms.gle/Wt6Zje4J5rm1yUfJ9',
    changewalleturl: 'https://testnet.wallet.pandoproject.org/',
    rtSevicesApiUri:'https://rtservices.pandoproject.org/apis',
    refreshToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InB1YmxpY3VzZXJAcGFuZG9wcm9qZWN0Lm9yZyIsInJ0SWQiOiJpdGlzbXl0aW1lcGFuZG9wcm9qZWN0QDIwMjJAMjIzMiM0NSIsImlhdCI6MTY3NjQ2MjU1OSwiZXhwIjoxNjgzNjMzNzU5fQ.7FCK1TFt-koG4ztWMDjz7iF2Fn5sOb8r-BZUVAngkXQ'
};





export default config;
