import React from "react";
import './TokenPage.css';
import config from "../Config";
import $ from "jquery";
import Web3 from 'web3';
import Wallet from "../services/Wallet";
import pnc20 from "../pnc20";
const rpcURL = config.rpcUrl
const web3 = new Web3(rpcURL);


class TokensPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { contractAddress: null, tokenSymbol: null, tokeName: null, decimal: 0, listToken: [], balance: 0, isAddress: false, name: 'aa' };
        this.handleChange = this.handleChange.bind(this);
        this.listToken = this.listToken.bind(this);
        this.removetoken = this.removetoken.bind(this)
    }
    listToken() {
        if (this.state.isAddress && this.state.tokenSymbol != null && this.state.tokeName != null && this.state.decimal > 0) {
            let obj = {
                contractAddress: this.state.contractAddress,
                tokenSymbol: this.state.tokenSymbol,
                tokeName: this.state.tokeName,
                decimal: this.state.decimal,
                balance: 'loading...'
            }
            let found = false
            let listTokens = this.state.listToken


            for (let i of listTokens) {
                if (i.contractAddress == obj.contractAddress) {
                    found = true;
                    i.tokenSymbol = this.state.tokenSymbol
                    i.tokeName = this.state.tokeName
                    i.decimal = this.state.decimal
                    i.balance = 'loading...'
                }
            }
            if (!found) {
                listTokens.push(obj)
            }

            localStorage.setItem(Wallet.getWalletAddress(), JSON.stringify(listTokens))
            this.setState({ listToken: listTokens, contractAddress: '', tokenSymbol: '', tokeName: '', decimal: '', isAddress: false })
            this.openPopup('close');
            this.getBalance()
        }
    }

    openPopup(action) {
        this.setState({ contractAddress: '', tokenSymbol: '', tokeName: '', decimal: '', isAddress: false })
        if (action == 'open') {
            $('#exampleModal').css({ 'display': 'block' })
            $('.modal-backdrop.fade').addClass('show')
            $('#exampleModal').addClass('show')
        }
        else {
            $('#exampleModal').css({ 'display': 'none' })
            $('.modal-backdrop.fade').removeClass('show')
            $('#exampleModal').removeClass('show')
        }
        console.clear()

    }

    isAddress(address) {
        try {
            return web3.utils.isAddress(address);
        }
        catch (e) {
            return false;
        }
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (name === 'contractAddress') {
            let isAddress = this.isAddress(value);
            this.setState({ isAddress: isAddress })
        }
        this.state[name] = value
        this.setState({ name: value })
    }

    componentDidMount() {
        window.scrollTo(0, document.body.scrollHeight);
        let listToken = JSON.parse(localStorage.getItem(Wallet.getWalletAddress()))
        if (listToken) {
            for (let i of listToken)
                this.setState({ listToken: listToken })
            setTimeout(() => {
                this.getBalance()
            }, 500)
        }
    }
    calcualteAmount = (amount) => {
        if (isNaN(amount)) {
          return "";
        }
        return amount.toFixed(2);
      };

    componentWillUnmount() {

    }



    async getBalance() {

        let walletAddress = Wallet.getWalletAddress();
        for (let i of this.state.listToken) {

            let pandoContract = new web3.eth.Contract(pnc20.abi, i.contractAddress);
            if (i.contractAddress == '0x58d2ded330a46Bce6167222cE7B960fc1dF20cC8') {
                i.price = 0.05;
            }
            else {
                i.price = 'N/A'
            }
            i.balance = await pandoContract.methods.balanceOf(walletAddress).call();
            i.balance = i.balance / (10 ** i.decimal);
            this.setState({ balance: 0 })
        }


    }
    removetoken(val) {
        let index = 0
        console.clear()
        let sure = window.confirm('Are you sure you want to remove token from list?')
        if (sure)
            for (let i of this.state.listToken) {
                if (i.contractAddress == val.contractAddress) {
                    this.state.listToken.splice(index, 1)
                    localStorage.setItem(Wallet.getWalletAddress(), JSON.stringify(this.state.listToken))
                    this.setState({ balance: 0 })
                    break;
                }
                index++
            }
    }



    render() {
        const { t, listToken } = this.props;
        return (

            <div>
                <section id="tokens-page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tok-flex">
                                    <h6>Tokens</h6>
                                    <img src="/img/Add Icon Wallet.svg" onClick={() => this.openPopup('open')}></img>
                                </div>
                                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-title">
                                                <img src="/img/Deposit Wallet.svg"></img>
                                                <h5>Add Token</h5>
                                            </div>
                                            <i className="fas fa-times close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.openPopup('close')}></i>
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-box">
                                                        <div class="row custom-top">
                                                            <div class="col-lg-12">
                                                                <div class="input-field">
                                                                    <span>Contract Address</span>
                                                                    <input type="text" name="contractAddress" placeholder="Enter contract address"
                                                                        value={this.state.contractAddress} onChange={this.handleChange} autoComplete="off" required></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="input-field">
                                                                    <span>Token Name</span>
                                                                    <input placeholder="Enter Token Name" type="text" name="tokeName" required
                                                                        value={this.state.tokeName} autoComplete="off" onChange={this.handleChange}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="input-field">
                                                                    <span>Symbol</span>
                                                                    <input placeholder="Enter Symbol" type="text" name="tokenSymbol" required
                                                                        value={this.state.tokenSymbol} autoComplete="off" onChange={this.handleChange}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="input-field">
                                                                    <span>Decimal</span>
                                                                    <input placeholder="Enter token decimal “7”, “9”" type="number" min="1" name="decimal"
                                                                        value={this.state.decimal} onChange={this.handleChange} required
                                                                        autoComplete="off"></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <button
                                                                    onClick={this.listToken}>Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.listToken.length > 0 ? (
                                    this.state.listToken.map((val, index) => {

                                        return (
                                            <div className="col-lg-12">


                                                <div className="token-box">
                                                    <div className="lefty-flex">
                                                        <span className="toknenicon">{val.tokenSymbol}</span>
                                                        <div className="des">
                                                            <h6>{val.tokeName} <span>({val.tokenSymbol})</span></h6>
                                                            <h6>Avail: {this.calcualteAmount(val.balance)}</h6>

                                                            {/* <b>+2.99%</b> */}
                                                            {/* </p> */}
                                                        </div>
                                                    </div>
                                                    <div className="righty">
                                                        <h6>  Price : ${val.price}</h6>
                                                        {
                                                            val.price == 'N/A' &&
                                                            <h6>Bal: ${val.price}</h6>
                                                        }
                                                        {
                                                            val.price != 'N/A' &&
                                                            <h6>Bal: ${this.calcualteAmount(val.price * val.balance)}</h6>
                                                        }

                                                        <div className="deletebtn" onClick={() => this.removetoken(val)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                            </svg>
                                                            {/* <span>Remove Token</span> */}
                                                        </div>
                                                    </div>

                                                </div>




                                            </div>
                                        )

                                    })
                                ) : (
                                    <div>
                                        No token listed yet!
                                    </div>
                                )}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default TokensPage;