import _ from "lodash";
import { connect } from "react-redux";
import React from "react";
import "./NavBar.css";
import Wallet from "../services/Wallet";
import { store } from "../state";
import { logout } from "../state/actions/Wallet";
import { copyToClipboard } from "../utils/Utils";
import Alerts from "../services/Alerts";
import { withTranslation } from "react-i18next";
import { Languages, lang } from "../constants/languageModal";
import { Link, NavLink } from "react-router-dom";
import config from "../Config";


//  This component is all about the navbar and its functionlity like language change,logout button,copy address
class NavBar extends React.Component {
  constructor() {
    super();
    this.logout = this.logout.bind(this);
    this.copyAddress = this.copyAddress.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = { lang: lang, languages: Languages, selectedLanguage: "" };
  }

  componentDidMount() {
    if (localStorage.getItem("language")) {
      this.setState({ selectedLanguage: localStorage.getItem("language") });
      this.props.i18n.changeLanguage(localStorage.getItem("language"));
    } else {
      this.setState({ selectedLanguage: this.props.i18n.language });
    }
  }

  logout() {
    store.dispatch(logout());
  }

  copyAddress() {
    let address = Wallet.getWalletAddress();
    copyToClipboard(address);
    Alerts.showSuccess("Your address has been copied");
  }

  changeLanguage = (lang) => {
    for (const key in this.state.languages) {
      if (key === lang) {
        this.setState({ selectedLanguage: lang });
        this.props.i18n.changeLanguage(this.state.languages[key]);
        localStorage.setItem("language", this.state.languages[key]);
        localStorage.setItem("lang", lang);
      }
    }
  };

  render() {
    const { network, i18n, t } = this.props;
    const address = Wallet.getWalletAddress();

    return (
      <div>

        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/home">
              <img
                src="/img/logo/pando_wallet_logo@2x.svg"
                alt="logo"
                className="logo-img-1"
              ></img>
            </Link>
            {!address ?
              <button
                className="navbar-toggler dropdown-toggle"
                type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              : ''}

            {!address ?
              <div
                className="collapse navbar-collapse dropdown-menu"
                id="navbarSupportedContent" aria-labelledby="dropdownMenuButton1"
              >

                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/home">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://pandoproject.org/mainnet-3-0/"
                      target="_blank"
                    >
                      Mainnet 3.0
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://explorer.pandoproject.org/"
                      target="_blank"
                    >
                      Explorer
                    </a>
                  </li>
                </ul>

                <form class="d-flex">
                  <div className="btns-box">
                    {/* <button className="btn1">Unlock</button> */}
                    <NavLink className="btn1" to="/unlock/keystore-file">
                      Unlock
                    </NavLink>
                    {/* <button className="btn2">Create Wallet</button> */}
                    <NavLink className="btn2" to="/create">
                      Create Wallet
                    </NavLink>
                  </div>
                </form>


              </div>
              : ''}

            {address ?
              <div className="custom-flex1">
                <div className="ver-fac">
                  <div className="networktype dropdown ">

                    {address ?
                      <p>Mainnet <span><a href="https://pandoproject.org/mainnet-3-0/" target="_blank">Version3.0</a></span></p>
                      : ''}

                  </div>
                </div>

              </div>
              : ''}

          </div>
        </nav>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    network: state.wallet.network,
  };
};

export default withTranslation()(connect(mapStateToProps)(NavBar));
//
